<template>
	<div class="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
		<div class="hidden w-full h-full absolute top-0 left-0 bg-black/10 z-0 dark:block" />
		<div class="flex justify-items-center mt-10 mx-auto sm:w-full sm:max-w-[580px] z-10">
			<div class="bg-white px-6 pt-12 pb-10 shadow sm:rounded-lg sm:px-12 sign-in-card dark:bg-gray-900 dark:border dark:border-white/5">
				<slot />
			</div>
		</div>
	</div>
	<PageFooter class="flex flex-col" />
</template>

<script lang="ts">
	export default { name: 'SimpleCard' } // name the component
</script>

<script setup lang="ts">
	// router
	import {
		useRoute,
		useRouter
	} from 'vue-router'

	import { 
		store as _userStore
	} from '@/store/user'

	import { 
		store as _jobStore
	} from '@/store/job'

	// vue
	import {
		onBeforeMount
	} from 'vue'
	import PageFooter from "@/components/PageFooter.vue"

	const route = useRoute()
	const router = useRouter()

	const userStore = _userStore()
	const jobStore = _jobStore()

	onBeforeMount(async () => {
		try {
			// check for sign out
			if(route.name === "SignOut") {
				jobStore.job.loggedIn = false
				router.push({name: "Login"})
			}
			// check for existing signing & reroute
			if(jobStore.job.loggedIn && route.name !== "SongSubmit") {
				router.push({name: "Search"})
			}
		}
		catch(e) {
			console.error(e)
		}
	})
</script>

<style>
	.sign-in-card > div > img {
		margin: auto ;
	}
</style>