<template>
	<div class="mb-2 content-center">
		<img
			src="@/assets/images/Crystal_Wedding_Logo_Primary_v2.svg"
			alt="Heartful Logo"
			class="flex h-40 w-64 mx-auto">
	</div>
	<p class="font-bold text-xl w-full mb-4 text-center">
		{{ t('welcome.welcomeHeader') }}
	</p>
	<WelcomeForm
		v-if="showWelcome" 
		v-model="showWelcome" />
	<LoginForm 
		v-else />		
</template>

<script lang="ts">
	export default { name: 'LoginMain' } 	// name the component
</script>

<script setup lang="ts">
	import LoginForm from './components/LoginForm.vue'
	import WelcomeForm from './components/WelcomeForm.vue'

	import {
		useRoute,
		useRouter
	} from 'vue-router'

	import { 
		store as _userStore
	} from '@/store/user'

	import { 
		store as _jobStore
	} from '@/store/job'

	import {
		computed,
		onBeforeMount,
		ref
	} from 'vue'

	// language
	import {
		useI18n
	} from 'vue-i18n'

	const {t} = useI18n()
	const router = useRouter()
	const userStore = _userStore()
	const jobStore = _jobStore()

	const currentRoute = computed(() => {
		return useRoute().name
	})

	const showWelcome = ref(true)

	onBeforeMount(async () => {
		try {
			// check for existing login & reroute
			if(jobStore.job.loggedIn) {
				// router.push({name: "Search"})
			}
		}
		catch(e) {
			console.log(e)
		}
	})


</script>
<style scoped>
	.min-h-260 {
		min-height: 65rem
	}
</style>