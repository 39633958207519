
import Login from './LoginMain.vue'
import SimpleCard from '@/layouts/SignIn/SimpleCard.vue'

const config = {
	mainRoute: {
		name: "Login",
		path: "/",
		component: Login,
		layout: SimpleCard
	}
}

export { config }