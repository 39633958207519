<template>
	<div
		role="group"
		class="col-span-full supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VDropdownSection' } // name the component
</script>