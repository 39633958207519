<template>
	<TransitionRoot
		:show="true"
		:as="`h${level}`"
		class="text-base/7 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white">
		<slot />
	</TransitionRoot>
</template>

<script lang="ts">
	export default { name: 'VSubheading' } // name the component
</script>

<script setup lang="ts">
	import {
		TransitionRoot
	} from '@headlessui/vue'

	const props = defineProps({
		level: {
			type: Number,
			default: 2
		}
	})
</script>