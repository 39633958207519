<template>
	<div class="w-full max-w-full sm:max-w-[310px]">
		<div
			tabindex="0"
			class="shadow-lg h-full bg-white rounded-md border-2 border-gray-200 items-center p-1"
			v-show="!loading">
			<div
				class="flex cursor-pointer transition-all duration-200 ease-in-out group"
				@click.stop="showSelect = !showSelect">
				<div
					class="h-15 w-15 bg-black relative flex-none rounded"
					@click.stop="playSong()">
					<img 
						v-show="result?.artworkUrl?.length && !loading"
						:src="result?.artworkUrl"
						:alt="result?.songName"
						class="object-cover rounded h-full w-full group-hover:opacity-50">
					<img
						v-show="!result?.artworkUrl?.length || loading"
						class="grayed-icon w-8 h-8 mx-auto translate-y-1/3 top-1/2"
						:class="{'animate-pulse': result?.artworkUrl?.length && loading}"
						src="@/assets/icons/solid/music-fill.svg"
						alt="list icon">
					<PlayIcon class="h-6 w-6 top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 absolute text-white hidden group-hover:block" />	
				</div>
				<div class="text-sm truncate ml-3">
					<p class="text-gray-900 font-semibold truncate">
						{{ result?.songName }}
					</p>
					<p class="text-gray-600 truncate">
						{{ result?.artist || "&nbsp;" }}
					</p>
				</div>
			</div>
			<!-- Mobile scene selection -->
			<div
				v-if="!scene"
				class="md:hidden flex gap-3 transition-all duration-200 px-2"
				:class="[showSelect ? 'h-22 mt-3' : 'h-0']">
				<v-field class="flex-1">
					<v-label class="text-sm">
						Select Scene
					</v-label>
					<v-select
						v-model="selectedSceneIndex"
						name="selected-scene">
						<template
							v-for="(scene, i) in sceneList"
							:key="i">
							<!-- <option :value="scene.recordId"> -->
							<option :value="i">
								{{ scene['job__JOBS_SCENES__jobID::zctSceneDisplayWeb'] }}
							</option>
						</template>
					</v-select>
				</v-field>
				<tw-button 
					@click="addSongToScene" 
					class="btn-brand font-medium h-11 w-11 mt-9">
					<PlusCircleIcon class="text-white h-6" />
				</tw-button>
			</div>
		</div>
		<div
			class="shadow-lg p-1 bg-white rounded-md border-2 border-gray-200 h-full"
			v-show="loading">
			<div class="flex">
				<div class="h-15 w-15 bg-gray-200 relative flex-none rounded animate-pulse">
					<img
						class="grayed-icon w-8 h-8 mx-auto translate-y-1/2"
						src="@/assets/icons/solid/music-fill.svg"
						alt="music note icon">
				</div>
				<div class="ml-3 w-full space-y-2">
					<div class="bg-gray-200 animate-pulse w-11/12 h-3 rounded-full mt-1" />
					<div class="bg-gray-200 animate-pulse w-1/2 h-3 rounded-full" />
					<div class="bg-gray-200 animate-pulse w-3/4 h-3 rounded-full" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'SongResult' } // name the component
</script>

<script setup lang="ts">
	// Icons
	import{
		PlayIcon,
		PlusCircleIcon
	} from '@heroicons/vue/24/solid'

	import{
		Bars3CenterLeftIcon,
		Bars3BottomLeftIcon,
	} from '@heroicons/vue/24/outline'

	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Store
	import { 
		store as _jobStore
	} from '@/store/job'

	// Types
	import{
		Scene
	} from '@/types'

	// Vue
	import {
		computed,
		defineEmits,
		inject,
		onMounted,
		ref
	} from "vue"

	const props = defineProps({
		loading: {
			type: Boolean,
			default: false
		},
		result: {
			type: Object,
			default: null
		},
		scene: {
			type: Boolean,
			default: false
		},
		loadArtwork: {
			type: Boolean,
			default: false
		}
	})

	const {t} = useI18n()
	const jobStore = _jobStore()
	const sceneList = ref(jobStore.job.jobDetails.scenes)
	const selectedSceneIndex = ref<number | null>(null)
	const selectedSceneId = ref<string | null>(null)
	const selectedScene = ref({})
	const showSelect = ref(false)

	const optionsList = computed(() => {
		return sceneList.value.map((scene) => {
			let sceneName = scene['job__JOBS_SCENES__jobID::zctSceneDisplayWeb']

			return {
				label: scene['job__JOBS_SCENES__jobID::zctSceneDisplayWeb'],
				value: scene.recordId
			}
		})
	})

	// const albumImgSrc = ref<string>(props?.result?.artworkUrl)
	// const loading = ref<boolean>(false)

	onMounted(async () => {
		if(!props.loadArtwork) return

		// loading.value = true

		// try {
		// 	albumImgSrc.value = await fetchS3File(props?.result?.artworkUrl)
		// 	loading.value = false
		// }
		// catch(e) {
		// 	console.log(e)
		// }	
	})

	const emit = defineEmits([
		'loading',
		'play'
	])

	const isPlaying = ref(false)

	const fetchS3File: any = inject('fetchS3File')
	async function playSong() {
		isPlaying.value = true
		emit('loading', true)
		const signedUrl = await fetchS3File(props.result.songFile)
		const artworkUrl = await fetchS3File(props.result.artworkFile)
		emit('play', {...props.result, signedUrl, artworkUrl})
		emit('loading', false)
	}

	/**
	 * Adds the song to the selected scene
	 * @function addSongToScene
	 */
	function addSongToScene() {

		let scene = sceneList.value[selectedSceneIndex.value]

		scene['job__JOBS_SCENES__jobID::zctSongID'] = props.result?.songId

		jobStore.job.jobDetails.scenes = sceneList.value
	}

</script>
