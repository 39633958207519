<template>
	<div
		class="space-y-4">
		<p class="text-justify font-light whitespace-pre-line">
			{{ t('welcome.welcomeMessage') }}
		</p>
		<div class="w-full flex justify-center">
			<tw-button
				class="btn-brand w-1/2"
				@click="goToLogin">
				{{ t('welcome.continueButton') }}
			</tw-button>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'WelcomeForm' } 	// name the component
</script>

<script setup lang="ts">

	import {
		useRoute,
		useRouter
	} from 'vue-router'

	import {
		computed,
		onBeforeMount,
		ref
	} from 'vue'
	// store

	import { 
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { 
		store as _valueListStore
	} from '@/store/valueLists'
    
	// language
	import {
		useI18n
	} from 'vue-i18n'

	const props = defineProps({
		modelValue: {
			type: Boolean,
			default: true
		},
	})

	const emit = defineEmits([
		'update:modelValue'
	])
	const {t} = useI18n()

	function goToLogin() {
		emit('update:modelValue', false)
	}

</script>
