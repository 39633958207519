import { 
	defineStore
} from 'pinia'
import { 
	useStorage
} from '@vueuse/core'

// Types
import {
	Job,
	JobDetail
} from '@/types'

export const store = defineStore('job', {
	// a function that returns a fresh state
	state: () => ({
        job: useStorage('job', {
			jobDetails: {} as JobDetail
        } as Job) 
	}),
	
	// optional getters
	getters: {
	},

	// optional actions
	actions: {
		// setUserData(state :any, payload :any) {
        //     state.user = payload
		// }
	}
})