<template>
	<div class="sticky top-0 pt-3 bg-white z-50">
		<h1 class="text-brand-600 text-3xl font-semibold mt-3 ml-6 ">
			{{ jobDetails.displayName }}
		</h1>
		<v-text class="mt-3 ml-6">
			{{ jobDetails.weddingDate }} - {{ $filters.hour24Time(jobDetails.weddingTime) }}
		</v-text>
	</div>


	<!-- Search filters -->
	<div class="sticky top-24 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white shadow-sm sm:gap-x-6 dark:border-white/5 dark:bg-gray-900">
		<form
			class="flex flex-col h-full w-full"
			@submit.prevent="getSongs">
			<!-- Mobile view -->
			<div class="sm:hidden mt-3.5">
				<div class="w-full h-full">	
					<SearchInput
						v-model="songSearchDetails.artist"
						:placeholder="t('searchFilter.artist')"
						aria-label="Artist"
						class="h-full rounded-none border-1 border-gray-200 text-sm"
						@update:model-value="searchOnStroke">
						<template #icon>
							<UserIcon />
						</template>
						<template #trailing_addon>
							<v-button 
								@click="toggleSearchFilterModal"
								plain>
								<ChevronDownIcon
									v-show="!openModal"
									class="-mr-1 h-5 w-5 text-gray-400"
									aria-hidden="true" />
								<ChevronUpIcon
									v-show="openModal"
									class="-mr-1 h-5 w-5 text-gray-400"
									aria-hidden="true" />
							</v-button>
						</template>
					</SearchInput>
				</div>
			</div>

			<!-- Desktop view -->
			<div class="grid-cols-4 w-full justify-items-center h-full hidden sm:grid">
				<div class="w-full h-full">	
					<SearchInput
						v-model="songSearchDetails.artist"
						:placeholder="t('searchFilter.artist')"
						aria-label="Artist"
						class="h-full rounded-none border-1 border-gray-200 text-sm"
						@update:model-value="searchOnStroke">
						<template #icon>
							<UserIcon />
						</template>
					</SearchInput>
				</div>
				<div class="w-full">
					<SearchInput
						v-model="songSearchDetails.songName"
						:placeholder="t('searchFilter.song')"
						aria-label="Song Name"
						class="h-full rounded-none border-1 border-gray-200 text-sm"
						@update:model-value="searchOnStroke">
						<template #icon>
							<MusicalNoteIcon />
						</template>
					</SearchInput>
				</div>
				<div class="w-full">	
					<SearchInput
						v-model="songSearchDetails.albumName"
						:placeholder="t('searchFilter.album')"
						aria-label="Album Name"
						class="h-full rounded-none border-1 border-gray-200 text-sm"
						@update:model-value="searchOnStroke">
						<template #icon>
							<Square3Stack3DIcon />
						</template>
					</SearchInput>
				</div>
				<div class="w-full">	
					<SearchInput
						v-model="songSearchDetails.genre"
						:placeholder="t('searchFilter.genre')"
						aria-label="Genre"
						class="h-full rounded-none border-1 border-gray-200 text-sm"
						@update:model-value="searchOnStroke">
						<template #icon>
							<Square3Stack3DIcon />
						</template>
					</SearchInput>
				</div>
			</div>
		</form>
	</div>

	<!-- Song Grid-->
	<div
		v-if="hasFilterApplied"
		class="flex w-full justify-center">
		<div class="grid grid-cols-1 sm:grid-cols-[310px,310px] xl:grid-cols-[310px,310px,310px] 2xl:grid-cols-[310px,310px,310px,310px] 3xl:grid-cols-[310px,310px,310px,310px,310px] overflow-y-auto gap-4 p-4 sm:w-auto w-full">
			<SongResult
				v-show="loading"
				v-for="i in 17"
				:key="i"
				:loading="loading" />
			<template
				v-for="(album, albumIndex) in albumList"
				:key="albumIndex">
				<Container
					class="hidden sm:block"
					v-for="(song, songIndex) in album.songList"
					:key="songIndex"
					tag="span"
					group-name="results"
					behaviour="copy"
					:get-child-payload="(i:number) => getSongIdByIndex(songIndex, albumIndex)"
					:should-animate-drop="shouldAnimateDrop"
					:on-drag-start="onDragStart"
					:on-drag-end="onDragEnd">
					<Draggable class="sm:max-w-[310px]">
						<SongResult 
							:class="['draggable-item', { 'opacity-50': draggingIndex[0] === albumIndex && draggingIndex[1] === songIndex }]"
							:result="song"
							@loading="loadingSong"
							@play="playSong" />
					</Draggable>
				</Container>
				<div
					v-for="(song, songIndex) in album.songList"
					:key="songIndex"	
					class="block sm:hidden sm:max-w-[310px]">
					<SongResult
						class="overflow-hidden"
						:result="song"
						@loading="loadingSong" />
				</div>
			</template>
		</div>
	</div>

	<!-- Placeholder text -->
	<div
		v-else
		class="max-w-150 mx-auto space-y-4 px-6 my-16">
		<img
			class="h-30 w-auto opacity-40 m-auto"
			src="@/assets/images/Crystal_Wedding_Logo_Submark_v2.svg"
			alt="Heartful Logo">
		<v-text>
			Welcome to Crystal Wedding's song selection. Please use the search above to browse our music catalog. When you have found a soung you want to select for a scene, simply drag it to that scene on the left.
		</v-text>
		<v-text>
			Once you are ready to submit your choices, click the Continue button.
		</v-text>
		<v-text>
			You will have up to one month before your wedding to return to this website to edit your choices.
		</v-text>
	</div>

	<!-- Song controls --> 
	<div class="fixed bottom-0 flex w-full right-0 z-50">
		<now-playing
			:loading="songLoading"
			:now-playing="nowPlayingSong" />
	</div>

	<!-- Search Filter Modal -->
	<div>
		<TransitionRoot
			as="template"
			:show="openModal">
			<Dialog
				as="div"
				class="relative z-50 lg:hidden"
				@close="openModal = false">
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0">
					<div class="fixed inset-0 bg-gray-900/80 mb-16" />
				</TransitionChild>
  
				<div class="fixed inset-0 flex w-full">
					<TransitionChild
						as="template"
						enter=" duration-500"
						enter-from="transform scale-95 opacity-0 max-h-0"
						enter-to="transform scale-100 opacity-100 max-h-96"
						leave="transition ease duration-300 transform"
						leave-from="transform scale-100 opacity-100 max-h-96 h-96"
						leave-to="transform scale-95 opacity-0 max-h-0">
						<DialogPanel class="relative my-16 flex w-full flex-1 dark:bg-gray-900">
							<div class="mt-24 h-[22rem] flex grow flex-col overflow-y-auto bg-white px-6 pb-4 dark:bg-black/10 dark:ring-1 dark:ring-white/10 pt-10">
								<div class="w-full mb-6">
									<SearchInput
										v-model="songSearchDetails.artist"
										:placeholder="t('searchFilter.artist')"
										aria-label="Artist"
										class="h-full rounded-none border-1 border-gray-200 text-sm"
										@update:model-value="searchOnStroke">
										<template #icon>
											<UserIcon />
										</template>
									</SearchInput>
								</div>
								<div class="w-full mb-6">
									<SearchInput
										v-model="songSearchDetails.songName"
										:placeholder="t('searchFilter.song')"
										aria-label="Song Name"
										class="h-full rounded-none border-1 border-gray-200 text-sm"
										@update:model-value="searchOnStroke">
										<template #icon>
											<MusicalNoteIcon />
										</template>
									</SearchInput>
								</div>
								<div class="w-full mb-6">	
									<SearchInput
										v-model="songSearchDetails.albumName"
										:placeholder="t('searchFilter.album')"
										aria-label="Album Name"
										class="h-full rounded-none border-1 border-gray-200 text-sm"
										@update:model-value="searchOnStroke">
										<template #icon>
											<Square3Stack3DIcon />
										</template>
									</SearchInput>
								</div>
								<div class="w-full">	
									<SearchInput
										v-model="songSearchDetails.genre"
										:placeholder="t('searchFilter.genre')"
										aria-label="Genre"
										class="h-full rounded-none border-1 border-gray-200 text-sm"
										@update:model-value="searchOnStroke">
										<template #icon>
											<Square3Stack3DIcon />
										</template>
									</SearchInput>
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
	</div>
</template>

<script lang="ts">
	export default { name: 'SearchMain' } // name the component
</script>

<script setup lang="ts">
	// Components
	import NowPlaying from './components/NowPlaying.vue'
	import SearchInput from './components/SearchInput.vue'
	import SongResult from './components/SongResult.vue'

	// Auth
	import { 
		Auth
	} from '@/api/Auth'

	// Drag n drop
	import { 
		Container, 
		Draggable 
	} from 'vue3-smooth-dnd'

	import EventBus from '@/events' // events

	// Headless UI
	import {
		Dialog,
		DialogPanel,
		Menu,
		TransitionChild,
		TransitionRoot,
	} from '@headlessui/vue'

	// Icons
	import{
		UserIcon,
		MusicalNoteIcon,
		Square3Stack3DIcon
	} from '@heroicons/vue/20/solid'
	
	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Queries
	import{
		getJobByRecordId,
		getSongByAlbumDetails
	}from './queries'

	// Store
	import { 
		store as _jobStore
	} from '@/store/job'
	import { 
		localesStore
	} from '@/store/locales'

	// Types
	import{
		Job,
		FindParameters,
		Song,
		Album,
		Scene
	} from '@/types'
    
	// Vue
	import {
		computed,
		defineEmits,
		inject,
		onBeforeUnmount,
		onMounted,
		ref
	} from 'vue'
	import {
		now,
		useDebounceFn
	} from "@vueuse/core"

	const emit = defineEmits([
		'updateCount'
	])

	const {t} = useI18n()

	// Variables
	const job = ref<Job>({} as Job)
	const songSearchDetails = ref({
		artist: '',
		songName: '',
		albumName: '',
		genre: ''
	})
	const fetchS3File: any = inject('fetchS3File')

	
	
	const hasFilterApplied = computed(() => {
		if(songSearchDetails.value.artist.length || songSearchDetails.value.songName.length || songSearchDetails.value.albumName.length || songSearchDetails.value.genre.length) return true
		// if(albumList.value?.length) return true
		return false
	})

	const albumList = ref<Album[]>([])

	const jobStore = _jobStore()
	const _localesStore = localesStore()
	const jobDetails = ref({
		displayName: "",
		weddingDate: "",
		weddingTime: "",
		scenes: []
	})

	// On Mount
	onMounted(() => {
		job.value = jobStore.job

		// Get Job Details
		getJobDetails()

		EventBus.on('play-song', async (payload) => {
			playSong(payload)
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('play-song', null)
	})

	// Searching Functions
	async function getJobDetails() {
		const scriptParams = JSON.stringify({
			language: _localesStore.getFmLanguage
		})

		let params: FindParameters = {
			query: [
				{'_kp_jobs_id': job.value.jobID}
			],
			'script.prerequest': 'Set Language',
			'script.prerequest.param': scriptParams
		} 
		try{
			let result = await getJobByRecordId(params)
			jobDetails.value = {
				displayName: result?.response?.data[0]?.fieldData?.name_bride_groom_display,
				weddingDate: result?.response?.data[0]?.fieldData?.date_start,
				weddingTime: result?.response?.data[0]?.fieldData?.time_start,
				scenes: (result?.response?.data[0]?.portalData?.portal_scenes) as []
			}
		}
		catch(e) {
			console.log(e)
		}

	}

	const loading = ref(false)
	async function getSongs() {
		loading.value = true
		if(albumList.value) albumList.value.length = 0

		const scriptParams = JSON.stringify({
			language: _localesStore.getFmLanguage
		})

		let params: FindParameters = {
			query: [
				{
					'name_search': songSearchDetails.value.albumName,
					'genre': songSearchDetails.value.genre,
					'album__ARTIST__artistID::artist_search': songSearchDetails.value.artist,
					'album__SONG__albumID::name_display': songSearchDetails.value.songName
				}
			],
			'script.prerequest': 'Set Language',
			'script.prerequest.param': scriptParams
		} 
		try {
			let result = await getSongByAlbumDetails(params)
			albumList.value = await Promise.all(result?.response?.data?.map(async (item: any) => {
				let signedUrl = await fetchS3File(item.fieldData['albums_ALBUMS_INFO_albumID::s3JSONPicture'])
				var album: Album = {
					albumName: item.fieldData.name_display,
					genre: item.fieldData.genre,
					songList: [],
					artworkFile: item.fieldData['albums_ALBUMS_INFO_albumID::s3JSONPicture'],
					signedUrl: signedUrl
				}
				for (let index in item.portalData.album__SONG__albumID) {
					album.songList.push({
						songId: item.portalData.album__SONG__albumID[index]['album__SONG__albumID::_kp_songs_id'],
						artist: item.portalData.album__SONG__albumID[index]['album__SONG__albumID::zctArtistDisplay'],
						songName: item.portalData.album__SONG__albumID[index]['album__SONG__albumID::name_display'],
						songFile: item.portalData.album__SONG__albumID[index]['album__song_SONGS_INFO__songID::s3JSONSong'],
						artworkFile: item.fieldData['albums_ALBUMS_INFO_albumID::s3JSONPicture'],
						artworkUrl: signedUrl
					})
				}
				return album
			}))
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	}

	const searchOnStroke = useDebounceFn(async () => {
		return await getSongs()
	}, 800)

	async function albumCovers() {
		try{
			for(let albumIndex in albumList.value) {
				let signedUrl = await fetchS3File(albumList.value[albumIndex].artworkFile)
				for(let songIndex in albumList.value[albumIndex].songList) {
					albumList.value[albumIndex].songList[songIndex].artworkFile = signedUrl
				}
			}
		}
		catch(e) {
			console.log(e)
		}
	}

	const nowPlayingSong = ref({
		artist: '',
		songName: '',
		albumName: '',
		genre: '',
		artworkUrl: '',
		signedUrl: ''
	})

	function playSong(nowPlaying: {
		artist: '',
		songName: '',
		albumName: '',
		genre: '',
		artworkUrl: '',
		signedUrl: ''
	}) {
		nowPlayingSong.value = nowPlaying
	}

	const songLoading = ref(false)
	function loadingSong(loading: boolean) {
		songLoading.value = loading
	}
	// #region Drag and Drop

	/**
	 * @function getSongIdByIndex
	 * @param songIndex - The dragged item's song index from the album
	 * @param albumIndex - The dragged item's album index from the album list
	 */
	function getSongIdByIndex(songIndex: any, albumIndex: number) {
		const songId = albumList?.value[albumIndex]?.songList[songIndex]?.songId
		const song = albumList?.value[albumIndex]?.songList[songIndex]
		return {songIndex, albumIndex, song}
	}

	/**
	 * @function shouldAnimateDrop -  Set dropping animation, called by the target container to which the dragged item will be droppped
	 */
	function shouldAnimateDrop() {
		return false
	}

	const draggingIndex = ref([null,null])
	function onDragStart({ isSource, payload }) {
		if(!isSource) return
		// payload holds the item being dragged, find the index and apply class
		draggingIndex.value = [payload.albumIndex, payload.songIndex]
	}

	function onDragEnd() {
		// Remove the dragging class when drag ends
		draggingIndex.value = [null,null]
	}

	// #endregion

	const openSearchFilterModal = ref<{open: boolean}>({open: false})
	const openModal = ref<boolean>(false)

	function toggleSearchFilterModal() {
		openSearchFilterModal.value.open = !openSearchFilterModal.value.open
		openModal.value = !openModal.value
	}

</script>