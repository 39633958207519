<template>
	<Menu
		as="div"
		class="relative">
		<slot />
	</Menu>
</template>

<script lang="ts">
	export default { name: 'VDropdown' }// name the component
</script>

<script setup lang="ts">
	import {
		Menu
	} from '@headlessui/vue'

	import {
		getCurrentInstance,
		provide
	} from 'vue'

	const menuId = 'menu-' + getCurrentInstance()?.uid
	provide('menuId', menuId)

</script>