<template>
	<p
		data-slot="description"
		:data-disabled="descriptionDisabled ? true : null"
		class="text-base/6 text-zinc-500 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-zinc-400">
		<slot />
	</p>
</template>

<script lang="ts">
	export default { name: 'VDescription' } // name the component
</script>

<script setup lang="ts">
	import {
		computed,
		inject
	} from "vue"

	const fieldDisabled = inject('fieldDisabled', computed(() => false))
	const fieldsetDisabled = inject('fieldsetDisabled', computed(() => false))
	const descriptionDisabled = computed(() => {
		return fieldDisabled.value || fieldsetDisabled.value
	})
</script>
