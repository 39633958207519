<template>
	<v-heading>{{ t('sidebar.additionalInfo') }}</v-heading>
	<v-sub-heading>{{ t('sidebar.cutoffDateMessageStart') }} {{ jobStore.job.numDaysUpdateSong }} {{ t('sidebar.cutoffDateMessageEnd') }}</v-sub-heading>
	<v-sub-heading>{{ t('sidebar.emailMessage') }}</v-sub-heading>

	<v-field>
		<v-label>{{ t('sidebar.groomEmail') }}</v-label>
		<v-input
			@input="() => emailError = ''"
			v-model="groomEmail"
			:invalid="emailError?.length"
			name="groomEmail" />
	</v-field>

	<v-field>
		<v-label>{{ t('sidebar.brideEmail') }}</v-label>
		<v-input
			@input="() => emailError = ''"
			v-model="brideEmail"
			:invalid="emailError?.length"
			name="brideEmail" />
	</v-field>

	<v-field>
		<v-label>{{ t('sidebar.notes') }}</v-label>
		<v-textarea
			v-model="notes"
			name="notes" />
	</v-field>

	<v-error-message v-if="emailError?.length">
		{{ emailError }}
	</v-error-message>

	<v-error-message v-if="scriptError?.length">
		{{ scriptError }}
	</v-error-message>

	<div class="mt-auto">
		<div class="flex justify-between gap-6">
			<tw-button
				@click="() => emit('navigateBack')"  
				class="font-medium w-full"
				class-name="text-blue-500"
				:loading="loading">
				{{ t('sidebar.back') }}
			</tw-button>
			<tw-button 
				@click="navigateNext"
				class="btn-brand font-medium w-full"
				:loading="loading">
				{{ t('sidebar.submit') }}
			</tw-button>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'AdditionalInfo' } // name the component
</script>

<script setup lang="ts">

	// Auth
	import { 
		Auth
	} from '@/api/Auth'

	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Router
	import {
		useRouter
	} from 'vue-router'

	// Store
	import { 
		store as _jobStore
	} from '@/store/job'

	// Vue
	import {
		ref
	} from 'vue'

	const {t} = useI18n()
	const jobStore = _jobStore()
	const router = useRouter()

	const groomEmail = ref<string | null>(null)
	const brideEmail = ref<string | null>(null)
	const notes = ref<string | null>(null)
	const emailError = ref<string>('')
	const scriptError = ref<string>('')
	const loading = ref<boolean>(false)

	async function navigateNext() {
		emailError.value = ''
		scriptError.value = ''

		// Verify either the bride/groom email has been entered
		if((!groomEmail.value || !groomEmail.value?.length) && (!brideEmail.value || !brideEmail.value?.length)) {
			emailError.value = t('sidebar.emailError')
			return
		}

		loading.value = true

		try {
			const jobId = jobStore.job?.jobID
			const scenesArray = jobStore.job?.jobDetails?.scenes
			const scenes = scenesArray?.map(scene => { return { sceneId: scene['job__JOBS_SCENES__jobID::_kp_jobs_scenes_id'], songId: scene['job__JOBS_SCENES__jobID::zctSongID'] }})
			const res = await Auth.submitSongs(jobId, scenes, brideEmail.value, groomEmail.value, notes.value)
			const scriptResult = JSON.parse(res?.response?.scriptResult)

			if(scriptResult.errorCode != 0) {
				const error = scriptResult.errorMessage
				throw error
			}

			router.push({name: "SongSubmit"})
		}
		catch(e) {
			console.log(e)
			scriptError.value = e
		}
		finally {
			loading.value = false
		}
		
	}

	const emit = defineEmits([
		'navigateBack'
	])

  </script>