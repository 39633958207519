<template>
	<p 
		data-slot="description"
		:class="clsx(className, 'col-span-2 col-start-2 row-start-2 text-sm/5 text-zinc-500 group-data-[focus]:text-white sm:text-xs/5 dark:text-zinc-400 forced-colors:group-data-[focus]:text-[HighlightText]')">
		<slot />
	</p>
</template>

<script lang="ts">
	export default { name: 'VDropdownDescription' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	const props = defineProps({
		className: {
			type: String,
			default: ''
		},
	})
</script>