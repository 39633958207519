<template>
	<div
		:id="labelId ?? undefined"
		:data-disabled="legendDisabled ? true : null"
		data-slot="legend"
		class="text-base/6 font-semibold text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VLegend' } // name the component
</script>

<script setup lang="ts">
	import {
		computed,
		inject
	} from "vue"

	const labelId: string = inject('labelId', null) || ''

	const fieldDisabled = inject('fieldDisabled', computed(() => false))
	const fieldsetDisabled = inject('fieldsetDisabled', computed(() => false))
	const legendDisabled = computed(() => {
		return fieldDisabled.value || fieldsetDisabled.value
	})
</script>