<template>
	<button
		type="button"
		@click.prevent="changeTheme()"
		class="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-zinc-900/5 dark:hover:bg-white/5"
		aria-label="Switch to light theme">
		<svg
			viewBox="0 0 20 20"
			fill="none"
			aria-hidden="true"
			:class="light"
			class="h-5 w-5 dark:hidden">
			<path d="M12.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
			<path
				stroke-linecap="round"
				d="M10 5.5v-1M13.182 6.818l.707-.707M14.5 10h1M13.182 13.182l.707.707M10 15.5v-1M6.11 13.889l.708-.707M4.5 10h1M6.11 6.111l.708.707" />
		</svg>
		<svg
			viewBox="0 0 20 20"
			fill="none"
			aria-hidden="true"
			:class="dark"
			class="hidden h-5 w-5 dark:block">
			<path d="M15.224 11.724a5.5 5.5 0 0 1-6.949-6.949 5.5 5.5 0 1 0 6.949 6.949Z" />
		</svg>
	</button>
</template>

<script lang="ts">
	export default { name: 'ThemeSwitchSimple' } // name the component
</script>

<script setup lang="ts">
	/* eslint-disable-next-line */
	const props = defineProps({
		light: {
			type: String,
			default: 'stroke-zinc-900'
		},
		dark: {
			type: String,
			default: 'stroke-white'
		},
	})
	
	function changeTheme() {
		// Whenever the user explicitly chooses light mode
		if (localStorage.theme === 'dark') localStorage.theme = 'light'

		// Whenever the user explicitly chooses dark mode
		else if (localStorage.theme === 'light') localStorage.theme = 'dark'

		let el: any = document?.querySelector("meta[name='theme-color']")

		if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
			document.documentElement.classList.add('dark')
			if(el) el.content = "#111827"
		}
		else {
			document.documentElement.classList.remove('dark')
			if(el) el.content = "#FFFFFF"
		}
	}
</script>
  