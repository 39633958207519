<template>
	<label
		:id="labelId ?? undefined"
		data-slot="label"
		:data-disabled="labelDisabled ? true : null"
		:for="controlId"
		class="select-none text-base/6 text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white"
		@click.prevent="clickHandler()">
		<slot />
	</label>
</template>

<script lang="ts">
	export default { name: 'VLabel' } // name the component
</script>

<script setup lang="ts">
	import {
		computed,
		inject
	} from "vue"

	const fieldDisabled = inject('fieldDisabled', computed(() => false))
	const fieldsetDisabled = inject('fieldsetDisabled', computed(() => false))
	const labelDisabled = computed(() => {
		return fieldDisabled.value || fieldsetDisabled.value
	})

	const controlId: string = inject('controlId', null) || ''
	const labelId: string = inject('labelId', null) || ''

	function clickHandler() {
		document.getElementById(controlId)?.click()
	}
</script>
