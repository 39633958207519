<template>
	<Teleport to="#teleports">
		<TransitionRoot
			leave="duration-100 ease-in"
			leave-to="opacity-0">
			<MenuItems
				:id="menuId + '-items'"
				:anchor="anchor"
				:style="'position: absolute; z-index: 999; ' + menuPlacementLeft + ' ' + menuPlacementTop + ' ' + buttonWidth + ' overflow: auto; max-width: 1498px; max-height: min(var(--anchor-max-height, 100vh), 600px);'"
				:class="clsx(
					className,
					// Anchor positioning
					'[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.1)] data-[anchor~=end]:[--anchor-offset:6px] data-[anchor~=start]:[--anchor-offset:-6px] sm:data-[anchor~=end]:[--anchor-offset:4px] sm:data-[anchor~=start]:[--anchor-offset:-4px]',
					// Base styles
					'isolate w-max rounded-xl p-1',
					// Invisible border that is only visible in `forced-colors` mode for accessibility purposes
					'outline outline-1 outline-transparent focus:outline-none',
					// Handle scrolling when menu won't fit in viewport
					'overflow-y-auto',
					// Popover background
					'bg-white/75 backdrop-blur-xl dark:bg-zinc-800/75',
					// Shadows
					'shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10',
					// Define grid at the menu level if subgrid is supported
					'supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]'
				)">
				<slot />
			</MenuItems>
		</TransitionRoot>
	</Teleport>
</template>

<script lang="ts">
	export default { name: 'VDropdownMenu' }// name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	import {
		MenuItems,
		TransitionRoot
	} from '@headlessui/vue'

	import {
		useScrollLock
	} from '@vueuse/core'

	import {
		inject,
		nextTick,
		onUpdated,
		ref
	} from "vue"

	const props = defineProps({
		anchor: {
			type: String,
			default: "bottom"
		},
		className: {
			type: String,
			default: ''
		},
	})

	const menuPlacementTop = ref('')
	const menuPlacementLeft = ref('')
	const buttonWidth = ref('')

	const isLocked = useScrollLock(document.body)

	onUpdated(async () => {
		const button = document.querySelector<HTMLElement>("[data-headlessui-state='open'] button")?.getBoundingClientRect()
		if(!button) {
			isLocked.value = false
			return
		}
		const bT = button?.top ?? 0
		const bB = button?.bottom ?? 0
		const bL = button?.left ?? 0
		const bR = button?.right ?? 0
		const bW = button?.width ?? 0
		const bH = button?.height ?? 0
		buttonWidth.value = `--button-width: ${bW}px;`

		nextTick(() => {
			const menu = document.getElementById(menuId + '-items')?.getBoundingClientRect()
			const mH = menu?.height ?? 0
			const mW = menu?.width ?? 0
			const s = window.scrollY
			const wH = window.innerHeight
			const wW = window.innerWidth

			// top position
			if(props.anchor.includes('start') && (props.anchor.includes('right') || props.anchor.includes('left'))) {
				menuPlacementTop.value = `top: ${bT - 4 + s}px;`
			}
			else if(props.anchor.includes('end') && (props.anchor.includes('right') || props.anchor.includes('left'))) {
				menuPlacementTop.value = `top: ${bB - mH + 4 + s}px;`
			}
			else if(props.anchor.includes('top')) {
				menuPlacementTop.value = `top: ${bT - mH - 8 > 0 ? bT - mH - 8 + s : bB + 8 + s}px;`
			}
			else if(props.anchor.includes('right') || props.anchor.includes('left')) {
				menuPlacementTop.value = `top: ${bT + ((bH - mH) / 2) + s}px;`
			}
			else if(props.anchor.includes('bottom')) {
				menuPlacementTop.value = `top: ${bB + 8 + mH < wH ? bB + 8 + s : bT - mH - 8 + s}px;`
			}
			else {
				menuPlacementTop.value = `top: ${bB + 8 + s}px;`
			}

			// left postion
			if(props.anchor.includes('start') && (props.anchor.includes('top') || props.anchor.includes('bottom'))) {
				menuPlacementLeft.value = `left: ${bL - 4}px;`
			}
			else if(props.anchor.includes('end') && (props.anchor.includes('top') || props.anchor.includes('bottom'))) {
				menuPlacementLeft.value = `left: ${bL + (bW - mW) + 4}px;`
			}
			else if(props.anchor.includes('right')) {
				menuPlacementLeft.value = `left: ${bR + 8 + mW < wW ? bR + 8 : bL - 8 - mW}px;`
			}
			else if(props.anchor.includes('left')) {
				menuPlacementLeft.value = `left: ${bL - 8 - mW > 0 ? bL - 8 - mW : bR + 8}px;`
			}
			else {
				menuPlacementLeft.value = `left: ${bL + ((bW - mW) / 2)}px;`
			}
			isLocked.value = true
		})
	})

	const menuId: string = inject('menuId', null) || ""
</script>