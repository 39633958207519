// Axios
import axios from 'axios'

// Router
import { 
	router
} from '@/router'

// Store
import { 
	store as _userStore
} from '@/store/user'

// Types
import{
	FmResponseMessage,
	SceneSongSubmission
} from '@/types'


 
interface Job {
	jobID: string
	loggedIn: boolean
}

export class AuthClass {

	/**
	 * Sign in
	 * @param {Object} weddingInfo - An object containing the groomName, brideName, weddingDate, and weddingTime
	 * @param {String} password - The password of the username
	 * @return - A promise resolves the user
	 */
	public signIn(
		weddingInfo: {
			groomName: string,
			brideName: string,
			weddingDate: string,
			weddingTimeHour: string,
			weddingTimeMinute: string
		},
		password: string
	): Promise<{authenticated: boolean, error: string, job: Job }> {

		const data = {
            groomName: weddingInfo.groomName,
            brideName: weddingInfo.brideName,
            weddingDate: convertDate(weddingInfo.weddingDate),
            weddingTime: weddingInfo.weddingTimeHour + ":" + weddingInfo.weddingTimeMinute,
			password: password,
		}
		
		return axios.post('login.php', JSON.stringify(data))
			.then(response => {
				if (response.data.loggedIn) {
					return {
						authenticated: true,
						error: '',     
						job: response.data
					}
				}
				else {
					return {
						authenticated: false,
						error: response.data.message,
						job: {} as Job
					}
				}    
			}, error => {
				console.log(error)
				return {
					authenticated: false,
					error: String(error),
					job: {} as Job
				}
			})
	}
	
	/**
	 * Sign out
	 * @
	 * @return - A promise resolved if success
	 */
	public signOut(): Promise<void> {
		const userStore = _userStore()
		
		return axios.post('logout.php')
			.then(() => {
				userStore.user = {
					userId: "",
					contactId: "",
					email: "",
					nameFirst: "",
					nameMiddle: "",
					nameLast: "",
					phone: "",
					sessionId: "",
					isPasswordChangeNeeded: "",
					contactType: "",
					language: "",
					recordId: "",
					loggedIn: false
				}
				router.push({name: 'Login'})
				delete localStorage.sessionId
				
			}, error => {
				console.log(error)
			})
	}
	
	public submitSongs(
		jobId: string,
		scenes: Array<SceneSongSubmission>,
		brideEmail?: string,
		groomEmail?: string,
		notes?: string
	): Promise<{}> {

		const parameters = JSON.stringify({
			jobId,
			scenes,
            brideEmail,
			groomEmail,
			notes
		})
		const postData = {
			fieldData : {
				postData: parameters
			},
			script : 'Scene - Submit Song Selection',
			'script.param' : parameters
		}
		
		return axios.post(`runScript.php`, postData)
			.then((response) => {
				return response.data
			})
			.catch((e) => {
				if(e.response.data.messages[0].message) {
					// Return FM Error Response
					return e.response.data
				}else{
					// Format Unknown Error Like a FM Error
					return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
				}
			})

		// return axios.post('runScript.php', postData)
		// 	.then(response => {
		// 			return {
		// 				code: response.data.errorMessage,     
		// 				message: response.data.errorMessage
		// 			}  
		// 	}, error => {
		// 		console.log(error)
		// 		return {
		// 			code: '500',
		// 			message: String(error)
		// 		}
		// 	})
	}
}

export const Auth = new AuthClass()

function convertDate(date: string) {
	const dateParts = date?.split('-')
	const year = dateParts[0]
	const month = dateParts[1]
	const day = dateParts[2]
	return `${month}/${day}/${year}`
}
