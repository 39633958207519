<template>
	<hr
		role="presentation"
		:class="[
			'w-full border-t',
			soft && 'border-zinc-950/5 dark:border-white/5',
			!soft && 'border-zinc-950/10 dark:border-white/10'
		]">
</template>
<script lang="ts">
	export default { name: 'VDivider' } // name the component
</script>
<script setup lang="ts">
/*eslint-disable*/
    const props = defineProps({
        soft: {
            type: Boolean,
            default: false
        }
    })
</script>