<template>
	<MenuButton
		:id="menuId + '-button'"
		:as="as">
		<slot />
	</MenuButton>
</template>

<script lang="ts">
	export default { name: 'VDropdownButton' }// name the component
</script>

<script setup lang="ts">
	import {
		MenuButton
	} from '@headlessui/vue'

	import VButton from '@/components/Buttons/VButton.vue'

	import {
		inject
	} from "vue"
	
	const props = defineProps({
		as: {
			type: Object,
			default: VButton
		}
	})

	const menuId: string = inject('menuId', null) || ""
</script>