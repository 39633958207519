<template>
	<div
		:id="labelId ?? undefined"
		data-slot="label"
		class="col-start-2 row-start-1">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VDropdownLabel' } // name the component
</script>

<script setup lang="ts">
	import {
		inject
	} from "vue"

	const labelId: string = inject('labelId', null) || ''
</script>
