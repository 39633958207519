<template>
	<div class="mb-2 content-center">
		<img
			src="@/assets/images/Crystal_Wedding_Logo_Primary_v2.svg"
			alt="Heartful Logo"
			class="flex h-40 w-64 mx-auto">
	</div>
	<p class="font-bold text-xl w-full mb-4 text-center">
		{{ t('confirmationModal.success') }}
	</p>
	<div class="space-y-4">
		<p class="text-justify font-light whitespace-pre-line">
			{{ t('confirmationModal.message') }}
		</p>
		<p class="text-justify font-light whitespace-pre-line">
			{{ t('confirmationModal.cutoffDateMessage') }}
		</p>
	</div>
	<div class="w-full flex justify-center mt-30">
		<tw-button
			class="btn-brand w-1/2"
			@click="() => router.push({name: 'Login'})">
			{{ t('confirmationModal.returnToLogin') }}
		</tw-button>
	</div>
</template>

<script lang="ts">
	export default { name: 'SongSubmit' } 	// name the component
</script>

<script setup lang="ts">

	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Router
	import {
		useRouter
	} from 'vue-router'

	const {t} = useI18n()
	const router = useRouter()

</script>