<template>
	<span
		data-slot="control"
		:class="classes">
		<slot />
	</span>
</template>

<script lang="ts">
	export default { name: 'VInputGroup' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	import {
		computed,
		provide,
		readonly
	} from 'vue'

	const props = defineProps({
		disabled: {
			type: Boolean,
			default: false
		}
	})

	const fieldDisabled = computed(() => {
		return props.disabled
	})
	provide('fieldDisabled', readonly(fieldDisabled))

	const classes = clsx(
		'relative isolate block',
		'[&_input]:has-[[data-slot=icon]:first-child]:pl-10 [&_input]:has-[[data-slot=icon]:last-child]:pr-10 sm:[&_input]:has-[[data-slot=icon]:first-child]:pl-8 sm:[&_input]:has-[[data-slot=icon]:last-child]:pr-8',
		'[&>[data-slot=icon]]:pointer-events-none [&>[data-slot=icon]]:absolute [&>[data-slot=icon]]:top-3 [&>[data-slot=icon]]:z-10 [&>[data-slot=icon]]:size-5 sm:[&>[data-slot=icon]]:top-2.5 sm:[&>[data-slot=icon]]:size-4',
		// icon position
		'[&>[data-slot=icon]:first-child]:left-3 sm:[&>[data-slot=icon]:first-child]:left-2.5 [&>[data-slot=icon]:last-child]:right-3 sm:[&>[data-slot=icon]:last-child]:right-2.5',
		// icon colors
		'[&>[data-slot=icon]]:text-zinc-500 dark:[&>[data-slot=icon]]:text-zinc-400'
	)
</script>
