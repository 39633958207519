<template>
	<span
		data-slot="control"
		:class="classes">
		<input
			:id="controlId ?? undefined"
			:aria-label="ariaLabel ?? undefined"
			:data-hover="hovered ? true : null"
			:disabled="inputDisabled"
			:data-disabled="inputDisabled ? true : null"
			:data-invalid="invalid ? true : null"
			:class="inputClasses"
			:list="list ? `list-${controlId}` : undefined"
			:value="modelValue"
			:autocomplete="autocomplete ? autocomplete : undefined"
			:name="name ? name : undefined"
			:required="inputRequired ? true : undefined"
			:placeholder="placeholder"
			:type="type"
			@blur="onBlur"
			@mouseover="hovered = true"
			@mouseout="hovered = false"
			@input="onInput">
	</span>
</template>

<script lang="ts">
	export default { name: 'VInput' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	import {
		computed,
		nextTick,
		inject,
		ref
	} from "vue"

	const props = defineProps({
		modelValue: {
			type: [String, Number],
			default: null
		},
		autocomplete: {
			type: String,
			default: ''
		},
		ariaLabel: {
			type: String,
			default: ''
		},
		className: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ''
		},
		invalid: {
			type: Boolean,
			default: false
		},
		list: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ''
		},
		onBlur: {
			type: Function,
			default: () => {}
		},
		placeholder: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'text'
		}
	})

	const fieldDisabled = inject('fieldDisabled', computed(() => false))
	const fieldsetDisabled = inject('fieldsetDisabled', computed(() => false))
	const inputDisabled = computed(() => {
		return fieldDisabled.value || fieldsetDisabled.value || props.disabled
	})

	const fieldRequired = inject('fieldRequired', computed(() => false))
	const fieldSetRequired = inject('fieldsetRequired', computed(() => false))
	const inputRequired = computed<boolean>(() => {
		return fieldRequired.value || fieldSetRequired.value || props.required
	})

	const controlId: string = props.id || (inject('controlId', null) ?? "")
	
	const emit = defineEmits([
		'update:modelValue'
	])

	const dateTypes = ['date', 'datetime-local', 'month', 'time', 'week']

	const classes = clsx([
		props.className,
		// Basic layout
		'relative block w-full',
		// Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
		'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow',
		// Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
		'dark:before:hidden',
		// Focus ring
		'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-blue-500',
		// Disabled state
		'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none',
		// Invalid state
		'before:has-[[data-invalid]]:shadow-red-500/10',
	])

	const inputClasses = clsx([
		props.className,
		// Date classes
		props.type &&
			dateTypes.includes(props.type) && [
				'[&::-webkit-datetime-edit-fields-wrapper]:p-0',
				'[&::-webkit-date-and-time-value]:min-h-[1.5em]',
				'[&::-webkit-datetime-edit]:inline-flex',
				'[&::-webkit-datetime-edit]:p-0',
				'[&::-webkit-datetime-edit-year-field]:p-0',
				'[&::-webkit-datetime-edit-month-field]:p-0',
				'[&::-webkit-datetime-edit-day-field]:p-0',
				'[&::-webkit-datetime-edit-hour-field]:p-0',
				'[&::-webkit-datetime-edit-minute-field]:p-0',
				'[&::-webkit-datetime-edit-second-field]:p-0',
				'[&::-webkit-datetime-edit-millisecond-field]:p-0',
				'[&::-webkit-datetime-edit-meridiem-field]:p-0',
			],
		// Basic layout
		'relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
		// Typography
		'text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white',
		// Border
		'border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20',
		// Background color
		'bg-transparent dark:bg-white/5',
		// Hide default focus styles
		'focus:outline-none',
		// Invalid state
		'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500',
		// Disabled state
		'data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]',
	])

	// update modeled value
	const onInput = async ({ target }: any) => {
		if(inputDisabled.value) return
		emit('update:modelValue', target.value)
		await nextTick()
		return
	}

	const hovered = ref(false)
</script>