
import SongSubmit from './SongSubmit.vue'
import SimpleCard from '@/layouts/SignIn/SimpleCard.vue'

const config = {
	mainRoute: {
		name: "SongSubmit",
		path: "/song-submit",
		component: SongSubmit,
		layout: SimpleCard
	}
}

export { config }