<template>
	<a
		@mouseover="hovered = true"
		@mouseout="hovered = false"
		:data-hover="hovered ? true : null"
		class="text-zinc-950 underline decoration-zinc-950/50 data-[hover]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white cursor-pointer">
		<slot />
	</a>
</template>

<script lang="ts">
	export default { name: 'VTextLink' } // name the component
</script>

<script setup lang="ts">
	import {
		ref
	} from 'vue'
	const hovered = ref(false)
</script>