/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { router } from './router/index'
import './assets/css/index.css'
import axios from 'axios'
import {
	createI18n,
	useI18n
 } from 'vue-i18n'

import { 
	localesStore
} from '@/store/locales'

// AWS
var AWS = require('aws-sdk/global');
import S3 from 'aws-sdk/clients/s3.js'; 
// import global components
import TwBadge from '@/components/Badges/TwBadge.vue'
import TwBreadcrumb from '@/components/Breadcrumbs/TwBreadcrumb.vue'
import TwButton from '@/components/Buttons/TwButton.vue'
import TwCheckbox from '@/components/Checkboxes/TwCheckbox.vue'
import TwInput from '@/components/InputGroups/TwInput.vue'
import TwLoading from '@/components/Loading/TwLoading.vue'
import TwSelect from '@/components/SelectMenus/TwSelect.vue'
import TwTable from '@/components/Tables/TwTable.vue'
import TwTableColumn from '@/components/Tables/TwTableColumn.vue'
import DataDisplay from '@/components/DataDisplay/DataDisplay.vue'
import ThemeSwitchSimple from '@/components/Buttons/ThemeSwitchSimple.vue'

/* Button */
import VButton from '@/components/Buttons/VButton.vue'
/* Dropdown */
import VDropdown from '@/components/Dropdowns/VDropdown.vue'
import VDropdownButton from '@/components/Dropdowns/VDropdownButton.vue'
import VDropdownDescription from '@/components/Dropdowns/VDropdownDescription.vue'
import VDropdownDivider from '@/components/Dropdowns/VDropdownDivider.vue'
import VDropdownHeading from '@/components/Dropdowns/VDropdownHeading.vue'
import VDropdownHeader from '@/components/Dropdowns/VDropdownHeader.vue'
import VDropdownItem from '@/components/Dropdowns/VDropdownItem.vue'
import VDropdownLabel from '@/components/Dropdowns/VDropdownLabel.vue'
import VDropdownMenu from '@/components/Dropdowns/VDropdownMenu.vue'
import VDropdownSection from '@/components/Dropdowns/VDropdownSection.vue'
import VDropdownShortcut from '@/components/Dropdowns/VDropdownShortcut.vue'
/* Fieldset */
import VDescription from '@/components/Fieldsets/VDescription.vue'
import VErrorMessage from '@/components/Fieldsets/VErrorMessage.vue'
import VField from '@/components/Fieldsets/VField.vue'
import VFieldset from '@/components/Fieldsets/VFieldset.vue'
import VFieldGroup from '@/components/Fieldsets/VFieldGroup.vue'
import VLabel from '@/components/Fieldsets/VLabel.vue'
import VLegend from '@/components/Fieldsets/VLegend.vue'
/* Heading */
import VHeading from '@/components/Headings/VHeading.vue'
import VSubheading from '@/components/Headings/VSubheading.vue'
/* Input */
import VInput from '@/components/InputGroups/VInput.vue'
import VInputGroup from '@/components/InputGroups/VInputGroup.vue'
import VTextarea from '@/components/InputGroups/VTextarea.vue'
/* Select */
import VSelect from '@/components/SelectMenus/VSelect.vue'
/* Text */
import VCode from '@/components/Text/VCode.vue'
import VStrong from '@/components/Text/VStrong.vue'
import VText from '@/components/Text/VText.vue'
import VTextLink from '@/components/Text/VTextLink.vue'
import VDivider from './components/Text/VDivider.vue'

import TwModal from '@/components/Modals/TwModal.vue'
import TwTabsUnderline from '@/components/Tabs/TwTabsUnderline.vue'
import CollapsableCard from '@/components/CollapsableCard.vue'
import TwNotification from '@/components/Notifications/TwNotification.vue'
// import SvgIcon from '@/components/SvgIcon.vue'

// import icons from Tailwind's Hero Icons
// Solid icons
import {
	BarsArrowUpIcon,
	ExclamationCircleIcon,
	MagnifyingGlassIcon,
	PlusCircleIcon,
	EyeIcon,
	EyeSlashIcon
} from '@heroicons/vue/24/solid'

// Outline icons
import { 
	XCircleIcon,
	XMarkIcon,
	ArrowRightIcon,
	ArrowLeftIcon,
	ChevronDownIcon,
	ChevronUpIcon
} from '@heroicons/vue/24/outline'

// Mini icons
import {
	ChevronDownIcon as ChevronDownIconMini,
	FunnelIcon as FunnelIconMini,
	CheckIcon as CheckIconMini,
	MinusIcon as MinusIconMini
} from '@heroicons/vue/20/solid'

const pinia = createPinia()
const app = createApp(App)


/**
 * Axios setup
 */
if(localStorage.sessionId == undefined) {
	localStorage.sessionId = Math.random().toString(36).substring(2)
}
let sessionId = localStorage.sessionId

if(window.location.hostname == 'localhost') {
    axios.defaults.baseURL = 'http://localhost:8888/heartful-music-catalog/api/v1/'
}
else {
    axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + '/api/v1/'
}
axios.defaults.headers.common['Session-Id'] = sessionId
axios.defaults.headers.common['Content-Type'] = 'application/json'


// Register icons globally
app.component('BarsArrowUpIcon', BarsArrowUpIcon)
app.component('ChevronDownIconMini', ChevronDownIconMini)
app.component('ExclamationCircleIcon', ExclamationCircleIcon)
app.component('FunnelIconMini', FunnelIconMini)
app.component('MagnifyingGlassIcon', MagnifyingGlassIcon)
app.component('PlusCircleIcon', PlusCircleIcon)
app.component('XCircleIcon', XCircleIcon)
app.component('XMarkIcon', XMarkIcon)
app.component('ArrowRightIcon', ArrowRightIcon)
app.component('ArrowLeftIcon', ArrowLeftIcon)
app.component('CheckIconMini', CheckIconMini)
app.component('MinusIconMini', MinusIconMini)
app.component('ChevronDownIcon', ChevronDownIcon)
app.component('EyeIcon', EyeIcon)
app.component('EyeSlashIcon', EyeSlashIcon)
app.component('ChevronUpIcon', ChevronUpIcon)

// Register global components
app.component("tw-badge", TwBadge)
app.component("tw-breadcrumb", TwBreadcrumb)
app.component("tw-button", TwButton)
app.component("tw-checkbox", TwCheckbox)
app.component("tw-input", TwInput)
app.component("tw-loading", TwLoading)
app.component("tw-table", TwTable)
app.component("tw-table-column", TwTableColumn)
app.component("tw-select", TwSelect)
app.component("data-display", DataDisplay)
app.component("tw-modal", TwModal)
app.component("tw-tabs-underline", TwTabsUnderline)
app.component("tw-notification", TwNotification)
app.component("collapsable-card", CollapsableCard)
app.component("theme-switch-simple", ThemeSwitchSimple)

/* Button */
app.component("v-button", VButton)
/* Dropdown */
app.component("v-dropdown", VDropdown)
app.component("v-dropdown-button", VDropdownButton)
app.component("v-dropdown-description", VDropdownDescription)
app.component("v-dropdown-divider", VDropdownDivider)
app.component("v-dropdown-header", VDropdownHeader)
app.component("v-dropdown-heading", VDropdownHeading)
app.component("v-dropdown-item", VDropdownItem)
app.component("v-dropdown-label", VDropdownLabel)
app.component("v-dropdown-menu", VDropdownMenu)
app.component("v-dropdown-section", VDropdownSection)
app.component("v-dropdown-shortcut", VDropdownShortcut)
/* Fieldset */
app.component("v-description", VDescription)
app.component("v-error-message", VErrorMessage)
app.component("v-field", VField)
app.component("v-fieldset", VFieldset)
app.component("v-field-group", VFieldGroup)
app.component("v-label", VLabel)
app.component("v-legend", VLegend)
/* Heading */
app.component("v-heading", VHeading)
app.component("v-sub-heading", VSubheading)
/* Input*/
app.component("v-input", VInput)
app.component("v-input-group", VInputGroup)
/* Select */
app.component("v-select", VSelect)
/* Text */
app.component("v-code", VCode)
app.component("v-strong", VStrong)
app.component("v-text", VText)
app.component("v-text-link", VTextLink)
app.component("v-divider", VDivider)
app.component("v-textarea", VTextarea)


// Set up global filters

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
	  $filters: {
		convertTime: (time: string|null|undefined) => number | string,
		hour24Time: (time: string|null|undefined) => number | string,
		percentage: (n: string|number) => string,
		timeDuration: (time: string|null|undefined) => number | string,
		jpy: (n: string|number|null|undefined) => string | null,
		secondsToTime: (seconds: number) => string
	  }
	}
}

app.config.globalProperties.$filters = {
	convertTime(time: string|null|undefined){
		if(!time) return ''
		
		let hours = Number(time.split(":")[0])
		let minutes = time.split(":")[1]

		// Determine AM/PM
		let timeOfDay = hours < 12 ? 'AM' : 'PM'
		
		// Convert to 12 hour time
		hours = hours < 13 ? hours === 0 ? 12 : hours : hours - 12

		return `${hours}:${minutes} ${timeOfDay}`		
	},
	hour24Time(time: string|null|undefined){
		if(!time) return ''
		
		let hours = Number(time.split(":")[0])
		let minutes = time.split(":")[1]

		return `${hours}:${minutes}`		
	},
	percentage(n: string|number) {
		return `${(parseFloat(String(n)) * 100).toFixed(2)}%`
	},
	timeDuration(time: string|null|undefined) {
		if(!time) return ''

		let hours = Number(time.split(":")[0])
		let minutes = time.split(":")[1]

		return `${hours}${useI18n().t('time.hour')} ${minutes === '00' ? '' : minutes + useI18n().t('time.minute')}`
	},
	jpy(n: string|number|null|undefined) {
		if(typeof n == "string") n = parseInt(n)
		return (n || n === 0 ? n.toLocaleString('ja-JP',{ style: "currency", currency: "JPY" }) : null)
	},
	secondsToTime(seconds: number) {
		const displayMinutes = String(Math.floor(seconds / 60))
		const displaySeconds = String(Math.floor(seconds % 60)).padStart(2, "0")
		return displayMinutes + ":" + displaySeconds
	}
}


// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'ap-northeast-1' // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	IdentityPoolId: 'ap-northeast-1:10d4d10f-0e9a-4aaa-838e-3b7019a62e64',
})

// Create a new service object
const s3 = new S3({
	apiVersion: '2006-03-01',
	params: {Bucket: process.env.VUE_APP_S3_BUCKET}
})
async function fetchS3File(field: string) {
	const json = JSON.parse(field || "{}")
	const key = json?.s3FilePathShortRaw

	if(key) {
		let params = {
			Bucket: process.env.VUE_APP_S3_BUCKET,
			Key: key
		}

		const signedUrl = s3.getSignedUrlPromise('getObject', params).then(
			function (url: string) {
				return url
			}, 
			function (err: Error) { 
				throw err
			}
		)
		return signedUrl
	}
	else {
		return ""
	}
}
app.provide('s3', s3)
app.provide("fetchS3File", fetchS3File)

app.use(pinia)
app.use(router)


// set up i18n
const _localesStore = localesStore()

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages :any = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
		const locale = matched[1]
		messages[locale] = locales(key)
    }
  })

  return messages
}

const i18n: any = createI18n({
	useScope: 'global',
	allowComposition: true,
	legacy: false,
	locale: _localesStore.locales.selected,
	fallbackLocale: 'en',
	messages: loadLocaleMessages()
})
app.use(i18n)
app.provide('i18n', i18n)


app.mount('#app')