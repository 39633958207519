<template>
	<kbd :class="clsx(className, 'col-start-5 row-start-1 flex justify-self-end')">
		<kbd
			v-for="(char,index) in (Array.isArray(keys) ? keys : keys.split(''))"
			:key="index"
			:class="clsx([
				'min-w-[2ch] text-center font-sans capitalize text-zinc-400 group-data-[focus]:text-white forced-colors:group-data-[focus]:text-[HighlightText]',
				// Make sure key names that are longer than one character (like 'Tab') have extra space
				index > 0 && char.length > 1 && 'pl-1',
			])">
			{{ char }}
		</kbd>
		<slot />
	</kbd>
</template>

<script lang="ts">
	export default { name: 'VDropdownShortcut' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	const props = defineProps({
		className: {
			type: String,
			default: ''
		},
		keys: {
			type: String,
			default: ''
		}
	})
</script>