<template>
	<p
		data-slot="error"
		:data-disabled="errorMessageDisabled ? true : null"
		class="text-base/6 text-red-600 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-red-500">
		<slot />
	</p>
</template>

<script lang="ts">
	export default { name: 'VErrorMessage' } // name the component
</script>

<script setup lang="ts">
	import {
		computed,
		inject
	} from "vue"

	const fieldDisabled = inject('fieldDisabled', computed(() => false))
	const fieldsetDisabled = inject('fieldsetDisabled', computed(() => false))
	const errorMessageDisabled = computed(() => {
		return fieldDisabled.value || fieldsetDisabled.value
	})
</script>
