
import SearchMain from './SearchMain.vue'

const config = {
	mainRoute: {
		name: "Search",
		path: "/search",
		component: SearchMain
	},
	nav: {
		name: 'nav.jobsMeetings',
		to: "Search",
		position: 1,
		show: true
	},
	module: "search"
}

export { config }