<template>
	<header
		role="presentation"
		:class="clsx(className, 'col-span-full grid grid-cols-[1fr,auto] gap-x-12 px-3.5 pb-1 pt-2 text-sm/5 font-medium text-zinc-500 sm:px-3 sm:text-xs/5 dark:text-zinc-400')">
		<slot />
	</header>
</template>

<script lang="ts">
	export default { name: 'VDropdownHeading' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	const props = defineProps({
		className: {
			type: String,
			default: ''
		},
	})
</script>