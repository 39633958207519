<template>
	<div
		role="none"
		:class="clsx(className, 'col-span-5 px-3.5 pb-1 pt-2.5 sm:px-3')">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VDropdownHeader' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	const props = defineProps({
		className: {
			type: String,
			default: ''
		},
	})
</script>