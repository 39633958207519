<template>
	<fieldset
		:aria-labelledby="labelId"
		:class="'[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1'">
		<slot />
	</fieldset>
</template>

<script lang="ts">
	export default { name: 'VFieldset' } // name the component
</script>

<script setup lang="ts">
	import {
		computed,
		getCurrentInstance,
		provide,
		readonly
	} from 'vue'

	const props = defineProps({
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		}
	})

	const fieldsetDisabled = computed(() => {
		return props.disabled
	})
	provide('fieldsetDisabled', readonly(fieldsetDisabled))

	const fieldsetRequired = computed(() => {
		return props.required
	})
	provide('fieldsetRequired', readonly(fieldsetRequired))

	const labelId = 'label-' + getCurrentInstance()?.uid
	provide('labelId', labelId)
</script>
