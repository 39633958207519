<template>
	<audio
		id="player"
		:src="nowPlaying.signedUrl"
		:ontimeupdate="updateTrackTime"
		:ondurationchange="updateDuration"
		:onloadeddata="updatingLoading"
		:onplay="onPlay"
		:onpause="onPause">
		<source
			:src="nowPlaying.signedUrl"
			type="audio/mpeg">
		{{ t("placeholders.audioNotSupported") }}
	</audio>

	<div
		@click="!expanded ? expandNowPlaying() : ''"
		class="relative shadow-lg md:rounded-none md:m-0 md:px-8 px-1.5 md:py-3  bg-zinc-800 w-full flex items-center justify-between transition-all origin-center duration-100 backdrop-blur overflow-hidden"
		:class="[expanded ? 'flex-col h-screen items-start justify-center gap-14 mx-0' : 'rounded-xl m-2 flex-row mb-18 h-15 z-[51]']">
		<div
			v-if="expanded"
			class="h-full w-full absolute top-0 left-0 block z-[-1] !bg-cover !bg-no-repeat !bg-center blur-3xl opacity-50 bg-zinc-500"
			:style="'background: url(' + nowPlaying.artworkUrl" />
		<div
			class="block md:hidden"
			:class="{'flex-col items-start justify-center h-1/2': expanded}">
			<button
				v-if="expanded"
				aria-label="Close now playing"
				@click.stop="expanded = !expanded">
				<XMarkIcon class="text-white h-6 w-6 flex-none absolute top-6 left-6" />
			</button>
			<div
				class="md:hidden bg-zinc-500 relative flex-none rounded-lg overflow-hidden shadow-lg transition-all duration-200"
				:class="[{'w-96 h-96 m-8 mt-16' : expanded && playing }, {'w-96 h-96 scale-75 m-8 mt-16' : expanded && !playing }, {'h-12 w-12' : !expanded} ]">
				<img
					v-show="nowPlaying.artworkUrl.length && !loading && loadedFile"
					:src="nowPlaying.artworkUrl"
					:alt="props.nowPlaying?.songName"
					class="object-cover rounded-lg h-full w-full group-hover:opacity-50 transition-all duration-100">
				<img
					v-show="!nowPlaying.artworkUrl.length || loading || !loadedFile"
					class="grayed-icon w-8 h-8 mx-auto translate-y-1/4 top-1/2"
					:class="{'animate-pulse': nowPlaying.artworkUrl.length && (loading || !loadedFile)}"
					src="@/assets/icons/solid/music-fill.svg"
					alt="list icon">
			</div>
		</div>
		<div
			class="w-full block md:hidden truncate"
			:class="[expanded ? 'text-xl px-6 h-full ' : 'text-base px-3']">
			<p class="md:hidden truncate text-white w-full">
				{{ nowPlaying.songName }}
			</p>
			<p
				v-show="expanded"
				class="truncate text-zinc-200/75">
				{{ [nowPlaying.artist, nowPlaying.albumName].filter(Boolean).join('—') }}
			</p>
			
			<div
				class="mt-6"
				v-show="expanded">
				<input
					v-show="!loading && loadedFile && nowPlaying.signedUrl.length"
					:style="style"
					id="track-seeker"
					type="range"
					:min="0"
					:max="duration"
					class="slider w-full bottom-0 left-0 h-1 bg-zinc-400 rounded-br appearance-none cursor-pointer"
					v-model="currentTime"
					@input="seekTrack">
			</div>
			<div
				class="w-full flex items-center justify-between mt-3"
				v-if="expanded">
				<p 
					v-show="expanded && !loading && loadedFile && nowPlaying.signedUrl.length" 
					class="text-sm text-zinc-200/75">
					{{ $filters.secondsToTime(currentTime) }}
				</p>
				<p 
					v-show="expanded && !loading && loadedFile && nowPlaying.signedUrl.length" 
					class="text-sm text-zinc-200/75">
					{{ $filters.secondsToTime(duration) }}
				</p>
			</div>
		</div>
		<div
			class="flex gap-4 mr-2 md:mr-0 h-full"
			:class="[expanded ? 'items-start' : 'items-center']">
			<button
				v-show="paused"
				@click.stop="play"
				:disabled="!nowPlaying.signedUrl.length || loading || !loadedFile"
				:class="{'opacity-50': !nowPlaying.signedUrl.length || loading || !loadedFile}">
				<PlayIcon
					class=" text-slate-200 hover:text-slate-50"
					:class="[expanded ? 'h-16 w-16' : 'h-6 w-6']" />
			</button> 
			<button
				v-show="playing"
				@click.stop="pause">
				<PauseIcon
					class="text-slate-200 hover:text-slate-50"
					:class="[expanded ? 'h-16 w-16' : 'h-6 w-6']" />
			</button>
		</div>
		<div class="hidden md:flex max-w-md w-full bg-zinc-600 h-13 rounded items-start">
			<div class="h-13 w-13 bg-zinc-500 relative flex-none rounded-l">
				<img
					v-show="nowPlaying.artworkUrl.length && !loading && loadedFile"
					:src="nowPlaying.artworkUrl"
					:alt="props.nowPlaying?.songName"
					class="object-cover rounded-l h-full w-full group-hover:opacity-50">
				<img
					v-show="!nowPlaying.artworkUrl.length || loading || !loadedFile"
					class="grayed-icon w-8 h-8 mx-auto translate-y-1/3 top-1/2"
					:class="{'animate-pulse': nowPlaying.artworkUrl.length && (loading || !loadedFile)}"
					src="@/assets/icons/solid/music-fill.svg"
					alt="list icon">
			</div>
			<div class="flex justify-between items-center flex-col w-full h-full truncate relative">
				<div class="px-4 text-sm w-full text-center">
					<img
						v-if="!nowPlaying.signedUrl.length"
						class="h-11 w-auto m-auto invert opacity-30"
						src="@/assets/images/Crystal_Wedding_Logo_Submark_v2.svg"
						alt="Heartful Logo">
					<p class="truncate text-white">
						{{ nowPlaying.songName }}
					</p>
					<p class="truncate text-zinc-300">
						{{ [nowPlaying.artist, nowPlaying.albumName].filter(Boolean).join('—') }}
					</p>
				</div>
				<p 
					v-show="!loading && loadedFile && nowPlaying.signedUrl.length" 
					class="absolute left-2 bottom-2 text-xs text-zinc-200">
					{{ $filters.secondsToTime(currentTime) }}
				</p>
				<p 
					v-show="!loading && loadedFile && nowPlaying.signedUrl.length" 
					class="absolute right-2 bottom-2 text-xs text-zinc-200">
					{{ $filters.secondsToTime(duration) }}
				</p>
				<div
					v-show="loading || !loadedFile || !nowPlaying.signedUrl.length"
					:class="{'animate-pulse': nowPlaying.artworkUrl.length && (loading || !loadedFile)}"
					class="w-full bottom-0 left-0 h-1 bg-zinc-400 rounded-br" 
					:style="style" />
				<input
					v-show="!loading && loadedFile && nowPlaying.signedUrl.length"
					:style="style"
					id="track-seeker"
					type="range"
					:min="0"
					:max="duration"
					class="slider w-full bottom-0 left-0 h-1 bg-zinc-400 rounded-br appearance-none cursor-pointer"
					v-model="currentTime"
					@input="seekTrack">
			</div>
		</div>
		<div class="hidden md:flex items-center gap-2">
			<button @click="muteVolume()">
				<SpeakerXMarkIcon class="h-5 w-5 text-slate-200 hover:text-slate-50" />
			</button>
			<input
				:style="volumeSliderStyle"
				id="volume-slider"
				type="range"
				:min="0"
				:max="1"
				step=".01"
				class="volume-slider w-full appearance-none cursor-pointer"
				v-model="volume"
				@input="setVolume">
			
			<button @click="maxVolume()">
				<SpeakerWaveIcon class="h-5 w-5 text-slate-200 hover:text-slate-50" />
			</button> 
		</div>
	</div>
</template>


<script lang="ts">
	export default { name: 'NowPlaying' } // name the component
</script>

<script setup lang="ts">
	import { // icons
		PlayIcon,
		PauseIcon,
		SpeakerWaveIcon,
		SpeakerXMarkIcon,
		XMarkIcon
	} from '@heroicons/vue/24/solid'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // vue
		ref,
		nextTick,
		watch,
		onUnmounted,
		onMounted
	} from 'vue'

	const props = defineProps({
		nowPlaying: {
			type: Object,
			default: () => {return {
				artist: '',
				songName: '',
				albumName: '',
				genre: '',
				signedUrl: '',
				artworkUrl: ''
			}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const {t} = useI18n()
	
	const currentTime = ref(0)
	const duration = ref(0)
	const completedPercentage = ref(0)
	const style = ref('')
	const volumeSliderStyle = ref(`background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF  100%, #A1A1AA 100%, #A1A1AA 100%) !important;`)
	const loadedFile = ref(false)
	const paused = ref(true)
	const playing = ref(false)
	const volume = ref(1)
	const expanded = ref(false)

	function updateTrackTime() {
		currentTime.value =  Math.floor(document.getElementById('player')?.currentTime)
		completedPercentage.value = (currentTime.value / duration.value) * 100
		style.value = `background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF  ${completedPercentage.value}%, #A1A1AA ${completedPercentage.value}%, #A1A1AA 100%) !important;`
	}

	function updateDuration() {
		duration.value = document.getElementById('player')?.duration
	}

	function updatingLoading() {
		loadedFile.value = true
		document.getElementById('player')?.play()
	}

	function seekTrack(time: number) {
		document.getElementById('player').currentTime = currentTime.value
	}

	function setVolume() {
		document.getElementById('player').volume = volume.value
		// update slider
		let value = (volume.value / 1) * 100
		volumeSliderStyle.value = `background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF  ${value}%, #A1A1AA ${value}%, #A1A1AA 100%) !important;`
	}

	function muteVolume() {
		volume.value = 0
		setVolume()
	}

	function maxVolume() {
		volume.value = 1
		setVolume()
	}

	function onPlay() {
		playing.value = true
		paused.value = false
	}

	function onPause() {
		playing.value = false
		paused.value = true
	}

	function play(event: any) {
		event.preventDefault()
		document.getElementById('player').play()
	}
	
	function pause(event: any) {
		event.preventDefault()
		document.getElementById('player').pause()
	}

	function expandNowPlaying() {
		const width = window.innerWidth
		if(width < 768) {
			expanded.value = !expanded.value
		}
	}

	const windowWidth = ref(0)
	function onResize() {
		windowWidth.value =  window.innerWidth
		if(windowWidth.value >= 768 && expanded.value) expanded.value = false
	}
	
	onMounted(async () => {
		await nextTick()
		window.addEventListener('resize', onResize)
	})

	onUnmounted(() => { 
		window.removeEventListener('resize', onResize)
	})

	watch(() => props.nowPlaying, async () => {
		loadedFile.value = false
	})
</script>

<style scoped>
	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		cursor: pointer;
		@apply bg-teal-500 h-4 w-1 border border-white;
	}

	.slider::-ms-thumb {
		-webkit-appearance: none;
		appearance: none;
		cursor: pointer;
		@apply bg-teal-500 h-4 w-1 border border-white;
	}

	.slider::-moz-range-thumb {
		cursor: pointer;
		@apply bg-teal-500 h-4 w-1 border border-white;
	}

	.slider {
		background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 0%, #A1A1AA 0%, #A1A1AA 100%);
		height: 4px;
		outline: none;
		transition: background 450ms ease-in;
		-webkit-appearance: none;
		appearance: none;
	}

	.volume-slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		border-radius: 50%;
		cursor: pointer;
		@apply bg-zinc-400 ring-2 ring-white h-3 w-3;
	}

	.volume-slider::-moz-range-thumb {
		border-radius: 50%;
		cursor: pointer;
		@apply bg-zinc-400 ring-2 ring-white h-3 w-3;
	}

	.volume-slider {
		background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 0%, #A1A1AA 0%, #A1A1AA 100%);
		height: 3px;
		@apply rounded-md;
		outline: none;
		/* transition: background 450ms ease-in; */
		-webkit-appearance: none;
		appearance: none;
	}
</style>