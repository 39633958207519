<template>
	<p
		data-slot="text"
		class="text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400">
		<slot />
	</p>
</template>

<script lang="ts">
	export default { name: 'VText' } // name the component
</script>